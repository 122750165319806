<div class="container py-2">
    <div style="display: flex; margin-left: -10px; align-items: center"> 
        <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
        <span>Response Details</span>
        <!-- <app-bread-crumb></app-bread-crumb> -->
    </div>
</div>
<div class="container" style="min-height: calc(100vh - 90px); padding: 10px; background: white;">
   <div *ngIf="csDetails">
        <h5>{{csDetails?.title}}</h5>
        <app-tag *ngIf="csDetails?.tag_ids.length>0" [tagsIds]="csDetails?.tag_ids" ></app-tag>
       
    </div>
    <hr>
  <app-statement-response-details [resDetails]="resDetails"></app-statement-response-details>
    <br>
       
    <hr>
    <div class="p-3">
        <app-comments (dataEvent)="receiveDataFromComment($event)" (downloadFileEvent)="downloadCommentFile($event)" [comments]="responseComment" [refresh]="refreshComment"></app-comments>
    </div>
</div>


