<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="px-3 pt-3">
  <app-bread-crumb></app-bread-crumb>
</div>

<div class="bg-color p-3">
  <div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7 p-0">
        <div class="single-center-pane">
          <router-outlet></router-outlet>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <!-- <app-right-panel-statement-details *ngIf="csDetails"></app-right-panel-statement-details> -->
            <div >
              <h6>Insights & Engagement Zone</h6>
              <div class="comingSoon text-center">
                  <img src="assets/images/analytics_bg.png" style="width: 100%; height: calc(100vh - 50vh); object-fit: cover; object-position: -236px; ">
                  <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
              </div>
            </div>
        </div>
    </div>
  </div>

    <!-- <router-outlet></router-outlet> -->
  </div>
  <app-footer></app-footer>
