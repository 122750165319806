import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { ProblemStatementsComponent } from './problem-statements/problem-statements.component';
import { ProblemStatementDetailComponent } from './problem-statement-detail/problem-statement-detail.component';
import { CreateResponseComponent } from './create-response/create-response.component';
import { StatementProviderResponseComponent } from './statement-provider-response/statement-provider-response.component';
const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: "" },
    
    children: [
      {
        path:'',
        redirectTo:'challenge-statement'
      },
      {
        path: 'challenge-statement',
        data: { breadcrumb: 'Challenge Statement' },
        component: ProblemStatementsComponent
      },
      {
        path: 'statement-details/:id',
        data: { breadcrumb: 'Challenge Statement' },
        component: ProblemStatementDetailComponent
      },
      {
        path: 'create-response/:id/:response_id',
        data: { breadcrumb: 'Challenge Statement' },
        component: CreateResponseComponent
      },
      {
        path: 'response-details/:id/:response_id',
        data: { breadcrumb: 'Response Details' },
        component: StatementProviderResponseComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProspectProviderWorkbenchRoutingModule { }
