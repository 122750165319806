<div class="d-flex">
  <div class=" d-flex img-container">
      <img class="img-align" src="./assets/images/bg3.png">
      <div class="centered fw-bold fs-14">Main Menu</div>
  </div>
</div>

<div>
  <div id="setBox">
      <mat-stepper class="mat-nav-list" #stepper orientation="vertical" (selectionChange)="onStepChange($event)">
          <mat-step class="px-1" label="{{menu.name}}" *ngFor="let menu of mainmenu">
              <ng-template matStepLabel>{{menu.name}}</ng-template>
              <ng-template matStepContent>
                  <ul class="nav-list mb-0 nodots">
                    <ng-container  *ngFor="let item of menu.children">
                      <!-- {{route?.url}} -->
                      <li (click)="navigateUrl(item.url)" routerLinkActive="active" *ngIf="route?.url != '/BVT/survey-details/results'" class="px-1" [class.active]="route?.url?.includes(item.url)"> {{item.name}}</li>
                      <li (click)="navigateUrl(item.url)" routerLinkActive="active" *ngIf="route?.url == '/BVT/survey-details/results'" class="px-1" [class.active]="item?.url?.includes('performanceInsights/hubble')"> {{item.name}}</li>
                    </ng-container>
                  </ul>
              </ng-template>
          </mat-step>
      </mat-stepper>
  </div>
</div>
