import { Component, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map, startWith, take } from 'rxjs/operators';
import { ViewEncapsulation } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-prospect-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProspectHeaderComponent implements OnInit {
  showProfilePopUp: boolean = false;
  showProfilePopUpMain: boolean =false;
  authenticated: boolean = false;
  staticText: any = (textConfiguration as any).default;
  
  user:any;
    constructor(
    private msalAuthService: MsalService,
    private dialog: MatDialog,private renderer: Renderer2,
    private router: Router, private datepipe: DatePipe, public fb: FormBuilder, private activatedRoute: ActivatedRoute, 
    private spinner: NgxSpinnerService, private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.activatedRoute.params.subscribe((res: any) => {
   
    })
  }

  ngOnInit() {
    if(localStorage.getItem('token')){
      this.authenticated = true;
      this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    }
    
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  logOut() {
    this.showProfilePopUp = false;
    this.showProfilePopUpMain = false;
    //It will solve respective issue when we click logout btn it's opening popup as mutiple instance it's kinda problem.
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = 'Logout';
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        if (localStorage.getItem('sso-login') == 'true') {
          this.msalAuthService.logoutRedirect();
        }
        localStorage.clear();
        window.location.reload();
        
      }
    })
  }
}
