<!-- <div class="container py-2">
    <div style="display: flex; margin-left: -10px; align-items: center"> 
        <mat-icon class="mr-2" (click)="goBack()">arrow_back</mat-icon>
        <span>Create/Update Response</span>
        <app-bread-crumb></app-bread-crumb>
    </div>
</div> -->
<div style="min-height: calc(100vh - 90px); background: white">
  <h5>{{ csDetails?.title }}</h5>
  <div>
    <div class="row">
      <div class="col-sm-6 trn-trc">
        <h6>Statement Response</h6>
      </div>
    </div>
    <form [formGroup]="challengeResponseForm">
      <div class="row mt-3">
        <div class="col-sm-12">
          <mat-form-field appearance="legacy" class="w-100 px-1">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              rows="3"
              formControlName="description"
            ></textarea>
          </mat-form-field>
          <app-tag
            [noIds]="true"
            [tagsIds]="createResponseTags"
            [vendorEdit]="true"
            (childTags)="getChildTags($event)"
            *ngIf="resId == 'new'"
          ></app-tag>
          <div *ngIf="resId != 'new' && createResponseTags">
            <app-tag
              [tagsIds]="createResponseTags"
              [dataObj]="getTagObject()"
              [vendorEdit]="true"
            ></app-tag>
          </div>
          <h6 class="mt-4">Attach File</h6>
          <!-- <div class="row">
                        <div class="col-sm-11">
                            <mat-form-field appearance="legacy" class="w-100 px-1" floatLabel="always">
                                <mat-label>Attachment Name</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-1" style="display: flex; justify-content: space-between">
                            <button mat-icon-button color="secondary">
                                <mat-icon>upload</mat-icon>
                            </button>
                            <button mat-icon-button color="success">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div> -->
          <mat-accordion formArrayName="files">
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              style="border-radius: 20px"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="d-flex justify-content-between">
                  <div class="mt-2">
                    Attachments ({{
                      fileControls.controls.length + responseFiles.length
                    }})
                  </div>
                  <button mat-icon-button color="success">
                    <input
                      hidden
                      #fileInput
                      type="file"
                      id="file"
                      (change)="onFileSelected($event)"
                    />
                    <mat-icon (click)="fileInput.click()">add</mat-icon>
                  </button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div style="padding: 20px; overflow-x: scroll">
                <div
                  class="d-flex flex-row flex-nowrap overflow-auto"
                  style="padding: 10px 0"
                >
                  <div
                    class="card card-block mx-2"
                    style="
                      width: 150px;
                      height: 150px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 15px;
                    "
                    *ngFor="let x of responseFiles; let i = index"
                  >
                    <div class="mr-2 mt-2">
                      <img
                        *ngIf="x?.file_type == 'doc' || x?.file_type == 'docx'"
                        src="./assets/images/word.svg"
                        height="40"
                      />
                      <img
                        *ngIf="x?.file_type == 'pdf'"
                        src="./assets/images/pdf.svg"
                        height="40"
                      />
                      <img
                        *ngIf="x?.file_type == 'xls' || x?.file_type == 'xlsx'"
                        src="./assets/images/excel.svg"
                        height="40"
                      />
                      <img
                        *ngIf="x?.file_type == 'csv'"
                        src="./assets/images/csv.png"
                        height="40"
                      />
                      <mat-icon
                        *ngIf="
                          x?.file_type !== 'pdf' &&
                          x?.file_type !== 'doc' &&
                          x?.file_type !== 'docx' &&
                          x?.file_type !== 'xls' &&
                          x?.file_type !== 'xlsx' &&
                          x?.file_type !== 'csv'
                        "
                        mat-list-icon
                        class="material-icons my-auto fs-1 text-danger"
                      >
                        description</mat-icon
                      >
                    </div>
                    <p class="m-0">{{ x?.file_name }}</p>
                    <mat-icon color="remove" (click)="DeleteFile(x?.id)"
                      >clear</mat-icon
                    >
                  </div>
                  <div
                    class="card card-block mx-2"
                    style="
                      width: 150px;
                      height: 150px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 15px;
                    "
                    *ngFor="let x of fileControls.controls; let i = index"
                  >
                    <div class="mr-2 mt-2">
                      <img
                        *ngIf="
                          getType(x?.value?.name) == 'doc' ||
                          getType(x?.value?.name) == 'docx'
                        "
                        src="./assets/images/word.svg"
                        height="40"
                      />
                      <img
                        *ngIf="getType(x?.value?.name) == 'pdf'"
                        src="./assets/images/pdf.svg"
                        height="40"
                      />
                      <img
                        *ngIf="
                          getType(x?.value?.name) == 'xls' ||
                          getType(x?.value?.name) == 'xlsx'
                        "
                        src="./assets/images/excel.svg"
                        height="40"
                      />
                      <img
                        *ngIf="getType(x?.value?.name) == 'csv'"
                        src="./assets/images/csv.png"
                        height="40"
                      />
                      <mat-icon
                        *ngIf="
                          getType(x?.value?.name) !== 'pdf' &&
                          getType(x?.value?.name) !== 'doc' &&
                          getType(x?.value?.name) !== 'docx' &&
                          getType(x?.value?.name) !== 'xls' &&
                          getType(x?.value?.name) !== 'xlsx' &&
                          getType(x?.value?.name) !== 'csv'
                        "
                        mat-list-icon
                        class="material-icons my-auto fs-1 text-danger"
                      >
                        description</mat-icon
                      >
                    </div>
                    <p class="m-0">{{ x?.value?.name }}</p>
                    <mat-icon color="remove" (click)="removeFile(i)"
                      >clear</mat-icon
                    >
                  </div>
                  <div
                    *ngIf="
                      fileControls.controls.length == 0 &&
                      responseFiles.length == 0
                    "
                  >
                    No file attached
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <h6 class="mt-4">Link</h6>
          <div class="row">
            <div class="col-sm-11">
              <mat-form-field appearance="legacy" class="w-100 px-1">
                <mat-label></mat-label>
                <input
                  matInput
                  [(ngModel)]="link"
                  [ngModelOptions]="{ standalone: true }"
                />
              </mat-form-field>
            </div>
            <div
              class="col-sm-1"
              style="display: flex; justify-content: space-between"
            >
              <button (click)="addLink()" mat-icon-button color="success">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="true"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              style="border-radius: 20px"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Links ({{ linkControls.controls.length }})
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                style="padding: 20px; overflow-x: scroll"
                *ngIf="linkControls.controls.length > 0"
              >
                <div
                  class="d-flex flex-row flex-nowrap overflow-auto"
                  style="padding: 10px 0"
                >
                  <div
                    class="card card-block mx-2"
                    style="
                      min-width: 150px;
                      height: 150px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 15px;
                    "
                    *ngFor="let x of linkControls.controls; let i = index"
                    (click)="navigateTolink(x?.value)"
                  >
                    <mat-icon>link</mat-icon>
                    <p class="m-0">Links {{ i + 1 }}</p>
                    <mat-icon color="remove" (click)="removeLink(i)"
                      >clear</mat-icon
                    >
                  </div>
                </div>
              </div>
              <div
                style="padding: 20px"
                *ngIf="linkControls.controls.length == 0"
              >
                No links added.
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </form>
    <div class="text-center mt-4">
      <button
        mat-button
        color="draft"
        aria-label="add"
        (click)="saveResponse('draft')"
        class="mr-1"
      >
        Save as Draft
      </button>
      <button
        mat-button
        color="success"
        aria-label="add"
        (click)="saveResponse('submitted')"
        class="ml-1"
      >
        Submit Response
      </button>
    </div>
  </div>
</div>
