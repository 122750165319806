import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { FormBuilder, FormGroup,FormArray,Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TagsService } from 'src/app/shared/services/tag.service';



@Component({
  selector: 'app-create-response',
  templateUrl: './create-response.component.html',
  styleUrls: ['./create-response.component.scss']
})
export class CreateResponseComponent implements OnInit {
  panelOpenState = false;
  panelOpenState1 = false;
  step: any = '1';
  csId:any;
  csDetails:any;
  createResponseTags:any;
  challengeResponseForm: FormGroup | any;
  link:any;
  resId:any;
  responseFiles:any = [];
  constructor(private router: Router,
    private route: ActivatedRoute, private location: Location,
    private spinner : NgxSpinnerService,
    private cw: ChallengeWorkbenchService,
    private fb: FormBuilder,
    private toastrService : ToastrService,
    private tagService: TagsService,
    
    ) { 
      this.challengeResponseForm = this.fb.group({
        description:['',Validators.required],
        files: this.fb.array([]),
        links: this.fb.array([]),
      });
    }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe((res: any) => {
      this.csId = atob(res.get('id'));
      this.resId = atob(res.get('response_id'))
      this.getChallengeStatementById();
      if(this.resId != 'new'){
        this.getResponseDetailsById();
      }
  });
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 100%, 40%, 80%)';
  } 

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  goBack(){
    this.location.back();
  }
  
  getChallengeStatementById(){
    this.spinner.show();
    this.cw.getChallengeStatementById(this.csId).subscribe((res:any)=>{
        this.spinner.hide();
        res.vendor.forEach((element:any) => {
            element.color = this.getGMapRandomColor();
        });
        res.problem_statement_okr.forEach((element:any) => {
            element.color = this.getGMapRandomColor();
        });
        this.csDetails = res;
    })
  }

  getChildTags(data: any){
    this.createResponseTags = data;
  }
  onFileSelected(event:any){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
     this.addFile(file);
    }
  }

  //==========================File Control===========================//
  createFileControl(file:any): FormControl {
    return this.fb.control(file); // You can set a default file if needed
  }

  addFile(file:any) {
    this.fileControls.push(this.createFileControl(file));
  }

  get fileControls() {
    return (this.challengeResponseForm.get('files') as FormArray);
  }
  removeFile(index: number) {
    this.fileControls.removeAt(index);
  }

  //============================Link Control=============================//
  createLinkControl(link:any): FormControl {
    return this.fb.control(link); // You can set a default file if needed
  }
  addLink(){
    if(this.link){
      this.linkControls.push(this.createLinkControl(this.link));
    }
    this.link = '';
  }
  get linkControls() {
    return (this.challengeResponseForm.get('links') as FormArray);
  }
  removeLink(index: number) {
    this.linkControls.removeAt(index);
  }
  //======================================================================//
  getType(x:any){
    const ext = x.substring(x.lastIndexOf('.') + 1);
    return ext?.toLowerCase();
  }

  navigateTolink(link:any){
    window.open(link, '_blank');
  }

  saveResponse(status:any){
    this.challengeResponseForm.markAllAsTouched();
    if(this.challengeResponseForm.invalid){
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('challengestatement_id',this.csId);
    formData.append('description',this.challengeResponseForm.value.description);
    formData.append('links',JSON.stringify(this.challengeResponseForm.value.links.length > 0 ? this.challengeResponseForm.value.links.join(',') : ''));
    this.challengeResponseForm.get('files').value.forEach((file:any) => { formData.append('files', file)});
    formData.append('status',status);
    if(this.resId == 'new'){
      this.createNewResponse(formData);
    }else{
      this.updateResponse(formData);
    }
    
  }

  createNewResponse(formData:any){
    this.cw.postServiceProviderResponse(formData).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Response Created Successfully.")
        this.addTags(res.id);
        this.router.navigate([`prospect-provider-workbench/challenge-statement`]);
      }
    });
  }

  updateResponse(formData:any){
    this.cw.updateServiceProviderResponse(formData,this.resId).subscribe(async (res:any)=>{
      if(res){
        this.spinner.hide();
        this.toastrService.success("Response Updated Successfully.")
        this.addTags(res.id);
        this.router.navigate([`prospect-provider-workbench/challenge-statement`]);
      }
    });
  }
  async addTags(id:any){
    if(this.createResponseTags?.length > 0){
      await this.createResponseTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "workbenchresponse",
          tag_id: element.id
        }
        await this.tagService.magTag(pst).subscribe((res:any) => {});
      });
    }
  }

  getResponseDetailsById(){
    this.spinner.hide();
    this.cw.getChallengeStatementResponseById(this.resId).subscribe((res:any)=>{
      this.spinner.hide();
      this.patchResponse(res);
    });
  }

  patchResponse(res:any){
    this.challengeResponseForm.setValue({
      description:res?.description,
      files:[],
      links:[]
    });
  this.responseFiles = res?.response_files;
    res.links.forEach((ele:any) => {
      this.link = ele;
      this.addLink();
    });
    this.createResponseTags = res?.tag_ids;
    this.spinner.hide();

  }

  DeleteFile(fileId:any){
    this.spinner.show();
    this.cw.deleteResponseAttachmentFile(fileId).subscribe((res:any)=>{
        this.spinner.hide();
        this.responseFiles = this.responseFiles?.filter((item:any) => item.id != fileId);
    });
  }

  getTagObject(){
    return {
      entity_id: this.resId,
      entity_type: "workbenchresponse"
    }
  }
}
