import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-statement-provider-response',
    templateUrl: './statement-provider-response.component.html',
    styleUrls: ['./statement-provider-response.component.scss']
})
export class StatementProviderResponseComponent implements OnInit {
    statements: any;
    panelOpenState: boolean = true;
    panelOpenState1: boolean = true;
    panelOpenState2: boolean = true;
    csId:any;
    resId:any;
    csDetails:any;
    resDetails:any;
    refreshComment:boolean=false;
    responseComment:any = [];
    constructor(private router: Router, private location: Location,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private cw: ChallengeWorkbenchService,
        private toastrService : ToastrService
        
        ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((res: any) => {
            this.csId = atob(res.get('id'));
            this.resId = atob(res.get('response_id'))
            this.getChallengeStatementById();
            this.getResponseDetailsById();
            this.getResponseComment();
        });
    }

    navigateTo(url: any) {
        this.router.navigateByUrl(url)
    }

    getGMapRandomColor() {
        return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
    }

    goBack(){
        this.location.back();
    }

    getChallengeStatementById(){
        this.spinner.show();
        this.cw.getChallengeStatementById(this.csId).subscribe((res:any)=>{
            this.spinner.hide();
            res.serviceprovider.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            res.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.csDetails = res;
            this.cw.setData(this.csDetails);
        })
    }

    getResponseDetailsById(){
        this.spinner.hide();
        this.cw.getChallengeStatementResponseById(this.resId).subscribe((res:any)=>{
            this.resDetails = res;
          this.spinner.hide();
        });
      }

    downloadFile(file:any){
        this.spinner.show();
        this.cw.downloadResponseAttachmentFile(file.id).subscribe((res: any) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute('download', file.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success('File downloaded successfully');
        this.spinner.hide();
        }, (error: any) => {
        this.spinner.hide();
        throw error;
        })
    }

    openLink(x:any){
        if(x){
            window.open(x, '_blank');
        }
    }

    getResponseComment(){
        this.cw.getResponseComments(this.resId).subscribe((res:any)=>{
            this.responseComment = res;
        })
    }

    receiveDataFromComment(event:any){
        this.spinner.show();
        this.refreshComment = false;
        const formData = new FormData();
        if(event?.text || event?.document){
            if(event.text)
            formData.append('text',event.text);
            if(event.document){
            formData.append('document',event.document);
            }
            formData.append('response_id',this.resId);
            formData.append('parent','');
            this.cw.postComments(formData).subscribe((res: any) => {
                this.responseComment.push(res);
                this.refreshComment = true;
                this.spinner.hide();
            })
        }   
    }

    downloadCommentFile(event:any){
        this.spinner.show();
        this.cw.downloadCommentFile(event.id).subscribe((res: any) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute('download', event.file_name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success('File downloaded successfully');
        this.spinner.hide();
        }, (error: any) => {
        this.spinner.hide();
        throw error;
        })
    }

    
    ngOnDestroy() {
        this.cw.setData('');
    }

}
