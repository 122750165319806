import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgPipesModule } from 'ngx-pipes';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { QuillModule } from 'ngx-quill';
import { ProspectProviderWorkbenchRoutingModule } from './prospect-provider-workbench-routing.module';
import { ProblemStatementsComponent } from './problem-statements/problem-statements.component';
import { ProspectHeaderComponent } from './header/header.component';
import { ProblemStatementDetailComponent } from './problem-statement-detail/problem-statement-detail.component';
import { CreateResponseComponent } from './create-response/create-response.component';
import { StatementProviderResponseComponent } from './statement-provider-response/statement-provider-response.component';
import { SideMenuWorkbenchComponent } from './side-menu/side-menu.component';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [
    IndexComponent,
    ProblemStatementsComponent,
    ProspectHeaderComponent,
    CreateResponseComponent,
    ProblemStatementDetailComponent,
    StatementProviderResponseComponent,
    SideMenuWorkbenchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    NgxPermissionsModule.forChild(),
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbModule,
    PipeModule,
    MatChipsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    NgPipesModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    QuillModule,
    ProspectProviderWorkbenchRoutingModule,
    MatStepperModule
    
  ]
})
export class ProspectProviderWorkbenchModule { }
