import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-problem-statements",
  templateUrl: "./problem-statements.component.html",
  styleUrls: ["./problem-statements.component.scss"],
})
export class ProblemStatementsComponent implements OnInit {
  statements: any = [];
  shortlistedStatements: any = [];
  rejectedStatement: any = [];
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private cw: ChallengeWorkbenchService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.statements = [
      {
        name: "AI enable in subscription collections Enhancing Food Delivery Efficiency for a Growing Restaurant Chain",
        color: this.getGMapRandomColor(),
        status: "New",
        recepient: 10,
        response: 4,
        last_updated_on: "2023-04-20",
      },
      {
        name: "Developing a Secure Mobile Banking App for Enhanced Customer Convenience",
        color: this.getGMapRandomColor(),
        status: "New",
        recepient: 30,
        response: 17,
        last_updated_on: "2023-04-22",
      },
      {
        name: "Optimizing Energy Consumption in Smart Homes to Reduce Utility Bills",
        color: this.getGMapRandomColor(),
        status: "Draft",
        recepient: 3,
        response: 0,
        last_updated_on: "2023-04-23",
      },
      {
        name: "Improving Student Retention Rates in an Online Education Platform",
        color: this.getGMapRandomColor(),
        status: "Submitted",
        recepient: 8,
        response: 5,
        last_updated_on: "2023-04-29",
      },
      {
        name: "Enhancing Supply Chain Visibility and Efficiency for a Global E-commerce Retailer",
        color: this.getGMapRandomColor(),
        status: "Submitted",
        recepient: 18,
        response: 5,
        last_updated_on: "2023-04-30",
      },
    ];

    this.getChallengeStatement();
  }

  navigateTo(url: any) {
    this.router.navigateByUrl(url);
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getChallengeStatement() {
    this.statements = [];
    this.shortlistedStatements = [];
    this.rejectedStatement = [];
    this.dataLoaded = false;
    this.spinner.show();
    this.cw.getChallengeStatement().subscribe((res: any) => {
      this.spinner.hide();
      res.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
        if (
          element.response_status == "new" ||
          element.response_status == "draft" ||
          element.response_status == "submitted"
        ) {
          this.statements.push(element);
        }
        if (element.response_status == "shortlist") {
          this.shortlistedStatements.push(element);
        }
        if (element.response_status == "reject") {
          this.rejectedStatement.push(element);
        }
      });
      this.dataLoaded = true;
    });
  }
  navigateById(url: any, id: any, response_id: any) {
    this.router.navigate([url + btoa(id) + "/" + btoa(response_id)]);
  }

  navigateToResponse(url: any, id: any) {
    this.router.navigate([url + btoa(id)]);
  }
}
