<div
  class="container"
  style="min-height: calc(100vh - 90px); padding: 15px; background: white"
>
  <div class="row">
    <div class="col-sm-6 trn-trc">
      <h5>Challenge Statements</h5>
    </div>
  </div>
  <app-loading *ngIf="!dataLoaded"></app-loading>

  <div *ngIf="dataLoaded" style="overflow-x: hidden">
    <div class="row mt-2">
      <div class="col-sm-8">
        <div style="overflow-x: scroll">
          <app-filter-card [pagetype]="'crowdsourcing'"></app-filter-card>
        </div>
      </div>
      <div class="col-sm-4 trn-tc-2">
        <input type="text" placeholder="Search ..." class="form-control" />
      </div>
    </div>

    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Active ({{ statements.length }})">
        <div class="tab-holder mt-4">
          <div class="flex-container-1">
            <div class="flex-item p-1" *ngFor="let x of statements">
              <div
                class="trn-x"
                [ngStyle]="{ background: x.color }"
                (click)="
                  navigateToResponse(
                    'prospect-provider-workbench/statement-details/',
                    x.id
                  )
                "
              >
                <div
                  class="st-col text-capitalize"
                  [ngStyle]="{
                    background:
                      x.response_status == 'new'
                        ? '#d38128'
                        : x.response_status == 'draft'
                        ? 'grey'
                        : x.response_status == 'submitted'
                        ? 'green'
                        : ''
                  }"
                >
                  {{ x.response_status }}
                </div>
                <h6
                  class="mb-0 trn-nm"
                  style="width: 80%"
                  matTooltip="{{ x.title }}"
                >
                  {{ x.title }}
                </h6>
                <div
                  style="padding: 10px; text-align: center; margin: 15px -15px"
                >
                  <h6 class="m-0 mb-2" style="font-size: 11px">Deadline</h6>
                  <h5 class="m-0" style="font-size: 14px">
                    {{ x.submission_date | date : "dd MMM, yyyy" }}
                  </h5>
                </div>
              </div>
              <div class="dos">
                <button
                  *ngIf="x.response_status == 'new'"
                  mat-button
                  color="response"
                  (click)="
                    navigateById(
                      'prospect-provider-workbench/create-response/',
                      x.id,
                      'new'
                    )
                  "
                >
                  <span> Create Response </span>
                </button>
                <button
                  *ngIf="x.response_status == 'draft'"
                  mat-button
                  color="response"
                  (click)="
                    navigateById(
                      'prospect-provider-workbench/create-response/',
                      x.id,
                      x.response_id
                    )
                  "
                >
                  <span> Submit Response </span>
                </button>
                <button
                  *ngIf="x.response_status == 'submitted'"
                  mat-button
                  color="response"
                  (click)="
                    navigateById(
                      'prospect-provider-workbench/response-details/',
                      x.id,
                      x.response_id
                    )
                  "
                >
                  <span> View Response </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Shortlisted ({{ shortlistedStatements.length }})">
        <div class="tab-holder mt-4">
          <div class="flex-container-1">
            <div class="flex-item p-1" *ngFor="let x of shortlistedStatements">
              <div
                class="trn-x"
                [ngStyle]="{ background: x.color }"
                (click)="
                  navigateToResponse(
                    'prospect-provider-workbench/statement-details/',
                    x.id
                  )
                "
              >
                <div
                  class="st-col text-capitalize"
                  [ngStyle]="{
                    background:
                      x.response_status == 'shortlist'
                        ? 'rgb(92, 141, 19)'
                        : 'grey'
                  }"
                >
                  {{ x.response_status }}
                </div>
                <h6
                  class="mb-0 trn-nm"
                  style="width: 80%"
                  matTooltip="{{ x.title }}"
                >
                  {{ x.title }}
                </h6>
                <div
                  style="padding: 10px; text-align: center; margin: 15px -15px"
                >
                  <h6 class="m-0 mb-2" style="font-size: 11px">Deadline</h6>
                  <h5 class="m-0" style="font-size: 14px">
                    {{ x.submission_date | date : "dd MMM, yyyy" }}
                  </h5>
                </div>
              </div>
              <div class="dos">
                <button
                  mat-button
                  color="response"
                  (click)="
                    navigateById(
                      'prospect-provider-workbench/response-details/',
                      x.id,
                      x.response_id
                    )
                  "
                >
                  <span> View Response </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Rejected ({{ rejectedStatement.length }})">
        <div class="tab-holder mt-4">
          <div class="flex-container-1">
            <div class="flex-item p-1" *ngFor="let x of rejectedStatement">
              <div
                class="trn-x"
                [ngStyle]="{ background: x.color }"
                (click)="
                  navigateToResponse(
                    'prospect-provider-workbench/statement-details/',
                    x.id
                  )
                "
              >
                <div
                  class="st-col text-capitalize"
                  [ngStyle]="{
                    background:
                      x.response_status == 'reject'
                        ? 'rgb(255, 85, 47)'
                        : 'grey'
                  }"
                >
                  {{ x.response_status }}
                </div>
                <h6
                  class="mb-0 trn-nm"
                  style="width: 80%"
                  matTooltip="{{ x.title }}"
                >
                  {{ x.title }}
                </h6>
                <div
                  style="padding: 10px; text-align: center; margin: 15px -15px"
                >
                  <h6 class="m-0 mb-2" style="font-size: 11px">Deadline</h6>
                  <h5 class="m-0" style="font-size: 14px">
                    {{ x.submission_date | date : "dd MMM, yyyy" }}
                  </h5>
                </div>
              </div>
              <div class="dos">
                <button
                  mat-button
                  color="response"
                  (click)="
                    navigateById(
                      'prospect-provider-workbench/response-details/',
                      x.id,
                      x.response_id
                    )
                  "
                >
                  <span> View Response </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
