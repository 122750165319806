<nav class="navbar navbar-expand-lg navbar-dark bg-lavendar py-0 justify-content-between fixed-top">
    <div class="w-100 d-flex nav-lg-holder " >
        <div class="navbar-brand mr-1">
            <span class="pr-2 d-inline-block">
                <a href="https://www.kairhos.com/" target="_blank">
                    <img src="assets/images/logo.svg" class="img-header cursor-pointer" width="100" />
                </a>
            </span>
            <span class="pl-2 border-left-nav d-inline-block">
                <a href="/" class="text-decoration-none">
                    <img src="assets/images/icon.png" class="keplerLogo" />
                </a>
            </span>
        </div>
    </div>
    <div class="d-flex align-items-centered d-none d-sm-none d-md-none d-lg-none d-xl-block display-xl-none" id="login">
        <ul class="navbar-nav d-flex align-items-center">
            <li class="nav-item dropdown d-flex flex-row align-items-center" (click)="showProfilePopUp=!showProfilePopUp" id="profile" *ngIf="authenticated">
                <ngx-avatar class="text-truncate"  matTooltip="{{user?.full_name}}" matTooltipClass="example-tooltip" [style]="customStyle" size="40"  [name]="user?.full_name">
                </ngx-avatar>
                <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5 ml-2 mt-2">
                    expand_more
                </mat-icon>
            </li>
        </ul>
    </div>
</nav>


<ng-container *ngIf="showProfilePopUp  && authenticated">
    <div class="log-container">
        <div class="arrow-top"></div>
        <div class="profile-body pt-4 p-2">
            <div class="d-flex flex-column cursor-pointer">
                <div class="p-1 border-bottom">
                    <h5 class="color-Port-Gore fs-14">{{staticText?.header?.user_profile}}</h5>
                </div>
                <div class="pt-3 pb-3 border-bottom">
                    <div class="d-flex flex-row justify-content-start align-items-start ">
                        <div class="user-icon p-1">
                            <ngx-avatar [name]="user?.full_name" bgColor="#92c353" size="38" [round]="true">
                            </ngx-avatar>
                        </div>
                        <div class="user-info d-flex flex-column p-1 mx-2">
                            <h5 class="fs-16 text-dark">
                                {{user?.full_name}}
                            </h5>
                            <p class="fs-14 fw-500 mb-0 text-dark mb-2 words-ellipse" data-bs-toggle="tooltip" data-bs-placement="bottom" [title]="user?.email">
                                {{user?.email}}
                            </p>
                            <!-- <div class="role-badge text-center">
                                <p class="fs-12 color-black-olive fw-500 mb-0">Delivery Manage</p>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="pt-3 pb-3">
                    <div class="d-flex flex-row justify-content-start align-items-center hover-box p-2" (click)="logOut()">
                        <div class="pe-2 ps-2">
                            <img src="assets/images/header/logout.svg" alt="logout" width="18" height="18">
                        </div>
                        <div class="pe-2 ps-2 text-capitalize">
                            <p class="color-black-olive fs-16 fw-500 mb-0">{{staticText?.header?.logout}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>